// import { ENVIRONMENT_INITIALIZER } from '@angular/core';
import { Routes, provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'reisen',
        pathMatch: 'full'
    },
    {
        path: ':id',
        children: [
            {
                path: 'preisalarm',
                loadChildren: () => import('../app/components/preisalarme/preisalarme.module').then(m => m.PreisalarmeModule)
            },
            {
                path: 'share',
                loadChildren: () => import('../app/components/shared-object/shared-object.module').then(m => m.SharedObjectModule)
            },
            {
                path: 'search',
                loadChildren: () => import('../app/components/search/search.module').then(m => m.SearchModule)
            },
            {
                path: 'link',
                loadChildren: () => import('../app/components/link/link.module').then(m => m.LinkModule)
            },
            {
                path: 'pathnewsletter',
                loadChildren: () => import('../app/components/newsletter/newsletter.module').then(m => m.NewsletterModule)
            },
            {
                path: 'reisebuero',
                loadChildren: () => import('../app/components/reisebuero/reisebuero.module').then(m => m.ReisebueroModule)
            },
            {
                path: 'customeraccount',
                loadChildren: () => import('../app/components/customeraccount/customeraccount.module').then(m => m.CustomeraccountModule)
            },
            {
                path: 'booking',
                loadChildren: () => import('../app/components/booking/booking.module').then(m => m.BookingModule)
            },
            {
                path: 'importinofferpack',
                loadChildren: () => import('../app/components/import-in-offerpack/import-in-offerpack.module').then(m => m.ImportInOfferpackModule)
            },
            {
                path: 'offerpack',
                loadChildren: () => import('../app/components/offerpack/offerpack.module').then(m => m.OfferpackModule),
            },
            {
                path: 'topregions',
                loadChildren: () => import('../app/components/topregions/topregions.module').then(m => m.TopregionsModule)
            },
            {
                path: 'pauschalreisen',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'pauschalreisen' }
            },
            {
                path: 'sktv',
                loadChildren: () => import('../app/components/sktv/sktv.module').then(m => m.SktvModule)
            },
            {
                path: 'offers',
                loadChildren: () => import('../app/components/offers/offers.module').then(m => m.OffersModule)
            },
            {
                path: 'hotellist',
                loadChildren: () => import('../app/components/hotels/hotels.module').then(m => m.HotelsModule)
            },
            {
                path: 'carrent',
                loadChildren: () => import('../app/components/carrent/carrent.module').then(m => m.CarrentModule),
            },
            {
                path: 'hotels',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'hotels' }
            },
            {
                path: 'mietwagen',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'mietwagen' }
            },
            {
                path: 'flug',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'flug' }
            },
            {
                path: 'kreuzfahrten',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'kreuzfahrten' }
            },
            {
                path: 'ferienhauser',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'ferienhauser' }
            },
            {
                path: 'ferienhause',
                loadChildren: () => import('../app/components/ferienhauser/ferienhauser.module').then(m => m.FerienhauserModule),
            },
            {
                path: 'ferienhauser-offer',
                loadChildren: () => import('../app/components/ferienhauseroffer/ferienhauseroffer.module').then(m => m.FerienhauserofferModule)
            },
            {
                path: 'ferienhauser-booking',
                loadChildren: () => import('../app/components/ferienhauserbooking/ferienhauserbooking.module').then(m => m.FerienhauserbookingModule)
            },
            {
                path: 'bahnreisen',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'bahnreisen' }
            },
            {
                path: 'angebote',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'angebote' }
            },
            {
                path: 'promo',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'promo' }
            },
            {
                path: 'urlaubsziele',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'urlaubsziele' }
            },
            {
                path: 'Reiseberichte',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'Reiseberichte' }
            },
            {
                path: 'service',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'service' }
            },
            {
                path: 'Sicherheitshinweise',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: 'Sicherheitshinweise' }
            },
            {
                path: 'ferienwohnung',
                loadChildren: () => import('../app/components/ferienwohnung/ferienwohnung.module').then(m => m.FerienwohnungModule)
            },
            {
                path: 'terminkalender',
                loadChildren: () => import('../app/components/terminkalender/terminkalender.module').then(m => m.TerminkalenderModule)
            },
            {
                path: 'about',
                loadChildren: () => import('../app/components/about/about.module').then(m => m.AboutModule)
            },
            {
                path: 'impressum',
                loadChildren: () => import('../app/components/impressum/impressum.module').then(m => m.ImpressumModule)
            },
            {
                path: 'datenschutz',
                loadChildren: () => import('../app/components/datenschutz/datenschutz.module').then(m => m.DatenschutzModule)
            },
            {
                path: 'agb',
                loadChildren: () => import('../app/components/agb/agb.module').then(m => m.AgbModule)
            },
            {
                path: 'contents',
                loadChildren: () => import('../app/components/contents/contents.module').then(m => m.ContentsModule)
            },
            {
                path: 'reisekompass',
                loadChildren: () => import('../app/components/contents/contents.module').then(m => m.ContentsModule)
            },
            {
                path: 'anfrage',
                loadChildren: () => import('../app/components/anfrage/anfrage.module').then(m => m.AnfrageModule)
            },
            {
                path: 'registration',
                loadChildren: () => import('../app/components/registration/registration.module').then(m => m.RegistrationModule)
            },
            {
                path: 'favorites',
                loadChildren: () => import('../app/components/favorites/favorites.module').then(m => m.FavoritesModule)
            },
            {
                path: 'flugs',
                loadChildren: () => import('../app/components/flugs/flugs.module').then(m => m.FlugsModule)
            },
            {
                path: 'cruise',
                loadChildren: () => import('../app/components/cruise/cruise.module').then(m => m.CruiseModule)
            },
            {
                path: 'camper',
                loadChildren: () => import('../app/components/capmer/capmer.module').then(m => m.CapmerModule),
                data: { path: 'camper' }
            },
            {
                path: ':mypath',
                loadChildren: () => import('../app/components/reisen/reisen-routing.module').then(m => m.routes),
                data: { path: '' }
            },
            {
                path: '',
                loadComponent: () =>
                    import('../app/components/home/home.component').then(m => m.HomeComponent)
            }
        ],
    },
    {
        path: '**',
        redirectTo: 'reisen'
    }
];

// const appFactory = (router: Router) => () => {
//     router.events.pipe(filter((event) => event instanceof Scroll)).subscribe({
//         next: (e: Scroll) => {
//             // some logic
//             console.log(e);
//         },
//     });
// };

export const AppRouteProviders: any[] = [
    provideRouter(
        routes,
        withInMemoryScrolling({
            // scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        }),
        withEnabledBlockingInitialNavigation(),
        // withRouterConfig({
        //     paramsInheritanceStrategy: 'always',
        //     onSameUrlNavigation: 'reload',
        // })
    ),
    // using ENIVRONMENT_INITIALIZER is like NgModule constructor calls
    // {
    //     provide: ENVIRONMENT_INITIALIZER,
    //     multi: true,
    //     useFactory: appFactory,
    //     deps: [Router],
    // },
];
